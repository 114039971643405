import * as _callBound2 from "call-bind/callBound";
var _callBound = _callBound2;
try {
  if ("default" in _callBound2) _callBound = _callBound2.default;
} catch (e) {}
import * as _isArrayBuffer2 from "is-array-buffer";
var _isArrayBuffer = _isArrayBuffer2;
try {
  if ("default" in _isArrayBuffer2) _isArrayBuffer = _isArrayBuffer2.default;
} catch (e) {}
var exports = {};
var callBound = _callBound;
var $byteLength = callBound("ArrayBuffer.prototype.byteLength", true);
var isArrayBuffer = _isArrayBuffer;

/** @type {import('.')} */
exports = function byteLength(ab) {
  if (!isArrayBuffer(ab)) {
    return NaN;
  }
  return $byteLength ? $byteLength(ab) : ab.byteLength;
}; // in node < 0.11, byteLength is an own nonconfigurable property
export default exports;